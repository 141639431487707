<template>
  <div class="setting d-flex flex-column align-items-center">
    <base-header
      v-if="$device.isMobile"
      :title="$t('設定')"
      :is-title-bold="true"
      :left-arrow="true"
      :back-on-click-left="true"
    ></base-header>
    <ul
      v-show="!isPasswordShow && !isRemoveAccountShow"
      class="list-group w-100"
    >
      <li
        v-if="!$device.isMobile"
        class="list-group-item title d-flex justify-content-between align-items-center position-relative"
      >
        <span>{{ $t('設定') }}</span>
        <span class="icon-X" @click.stop="$emit('close')"></span>
      </li>
      <li
        v-if="hasChessboradRoom"
        class="list-group-item d-flex align-items-center position-relative"
      >
        <span>{{ $t('音效') }}</span>
        <b-form-checkbox
          id="soundSettingCheck"
          v-model="soundSettingCheck"
          class="position-absolute"
          switch
          size="lg"
          @change="onSettingChange('sound')"
        ></b-form-checkbox>
      </li>
      <li
        v-if="hasChessboradRoom"
        class="list-group-item d-flex align-items-center position-relative"
      >
        <span>{{ $t('直接落子') }}</span>
        <b-form-checkbox
          id="laoziDirectSettingCheck"
          v-model="laoziDirectSettingCheck"
          class="position-absolute"
          switch
          size="lg"
          value="direct"
          unchecked-value="confirm"
          @change="onSettingChange('laozi')"
        ></b-form-checkbox>
      </li>
      <li
        v-if="hasChessboradRoom && !$isJungo"
        class="list-group-item d-flex align-items-center position-relative"
      >
        <span>{{ $t('棋盤座標') }}</span>
        <b-form-checkbox
          id="coordinatesSettingCheck"
          v-model="coordinatesSettingCheck"
          class="position-absolute"
          switch
          size="lg"
          @change="onSettingChange('coordinates')"
        ></b-form-checkbox>
      </li>
    </ul>
    <Password
      v-if="isPasswordShow"
      @click.native.stop
      @close="isPasswordShow = false"
    ></Password>
    <RemoveAccount
      v-if="isRemoveAccountShow"
      @click.native.stop
      @close="isRemoveAccountShow = false"
    ></RemoveAccount>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import userService from '@/services/user';

export default {
  components: {
    BaseHeader,
    Password: () => import('@/views/Personal/Password.vue'),
    RemoveAccount: () => import('@/views/Personal/RemoveAccount.vue'),
  },
  data() {
    return {
      soundSettingCheck: null,
      laoziDirectSettingCheck: null,
      coordinatesSettingCheck: null,
      prevRoute: null,
      isPasswordShow: false,
      isRemoveAccountShow: false,
    };
  },
  computed: {
    isCN() {
      return this.$store.getters['env/isCN'];
    },
    userData() {
      return this.$store.getters['user/userData'];
    },
    hasChessboradRoom() {
      return (
        this.$route.meta.chessboardRoom || this.prevRoute?.meta.chessboardRoom
      );
    },
    isTeacher() {
      return this.$store.getters['user/isTeacher'];
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.soundSettingCheck = this.userData.config.soundEffectSetting;
      this.laoziDirectSettingCheck = this.userData.config.laoziSetting;
      this.coordinatesSettingCheck = this.userData.config.hasOpenCoordinates;
    },
    onSettingChange(checkbox) {
      switch (checkbox) {
        case 'sound':
          this.$store.state.user.userData.config.soundEffectSetting =
            !this.$store.state.user.userData.config.soundEffectSetting;
          return this.updateUser({soundEffectSetting: this.soundSettingCheck});
        case 'coordinates':
          this.$store.state.user.userData.config.hasOpenCoordinates =
            !this.$store.state.user.userData.config.hasOpenCoordinates;
          return this.updateUser({
            hasOpenCoordinates: this.coordinatesSettingCheck,
          });
        case 'laozi':
          if (
            this.$store.state.user.userData.config.laoziSetting === 'confirm'
          ) {
            this.$store.state.user.userData.config.laoziSetting = 'direct';
          } else {
            this.$store.state.user.userData.config.laoziSetting = 'confirm';
          }
          return this.updateUser({laoziSetting: this.laoziDirectSettingCheck});
      }
    },
    async updateUser(settingChange) {
      await userService.updateUser(settingChange);
      this.$JPush.updateTags();
      this.$store.dispatch('user/loginWithJwt');
      if (!this.$device.isMobile) {
        this.$Message.success(this.$t('更改成功'), 3000);
        this.$emit('close');
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
};
</script>

<style lang="scss" scoped>
.setting {
  width: 100%;
  height: 100%;
  overflow: overlay;
  @media screen and (min-width: 768px) {
    height: auto;
    width: 300px;
    border-radius: 10px;
  }
}
.list-group-item {
  height: 50px;
  border: none;
  background-color: $bgcontainer;
  border-bottom: 1px solid rgba(199, 199, 199, 0.3);
  padding: 0 16px;
  &.title {
    @media screen and (min-width: 768px) {
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;
      color: $font-grayscale-1;
      padding: 16px 6px 16px 16px;
      .icon-X {
        font-size: 32px;
        padding: 0;
        color: $primary;
        cursor: pointer;
      }
    }
  }
  span {
    left: 16px;
  }
  .custom-switch {
    right: 6px;
    top: 9px;
  }
  .icon-Chevron-right {
    font-size: 27px;
    color: $font-grayscale-2;
  }
}
</style>
